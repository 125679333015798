import "@angular/localize/init"

(window as any).global = window;
//(window as any).process = {
//    env: { DEBUG: undefined },
//};


/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
* BROWSER POLYFILLS
*/
//  Angular 8 uses differential loading to support older browsers.  But it only covers what *IT* needs.
//  It's imports for ES5 (older browsers) are here:
//      https://github.com/clydin/angular-cli/blob/master/packages/angular_devkit/build_angular/src/angular-cli-files/models/es5-polyfills.js
//  If we reference anything else that needs a polyfill that is not included there, we need to import it ourself.
//  ** Also see here: https://github.com/angular/angular/issues/32228#issuecomment-523298500
//      where it says that the core-js polyfills are no longer needed for Angular itself.  But if our application needs them,
//      we still need to import them!
//  core-js is now included with Angular so we do not need to manually include it in our package.json.
//  Error in system log like any of these indicate a missing polyfill:
//      Object doesn't support property or method 'xxxx'
//      xxxx is not a function
//  Core-js docs/modules are here: https://github.com/zloirock/core-js
//import 'core-js';     If in doubt, can import this to get everything but trying to be more selective to make sure it doesn't bloat the build output
import 'core-js/features/string/pad-start';          //  Used by DateUtils
import 'core-js/features/string/replace-all';        //  Used by PersonDetails.html
import 'core-js/features/array/includes';            //  Used by PermissionService.userHasPermission 
import 'core-js/features/symbol/async-iterator';     //  Some function named updateOutlineGap - must be in 3rd party package


//  Angular Browser support: https://angular.io/guide/browser-support
//  Internet Explorer 11 also needs a pre v2.0.0 of the jsts package: https://github.com/bjornharrtell/jsts/releases/tag/2.0.0

//  from https://github.com/angular/angular/issues/31723
/*
* in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
* with the following flag, it will bypass `zone.js` patch for IE/Edge
*/
//(window as any).__Zone_enable_cross_context_check = true;
if (document['documentMode'] || /Edge/.test(navigator.userAgent)) {
    (window as any).__Zone_enable_cross_context_check = true;
}

if (navigator.userAgent.indexOf("Edge/") !== -1) {
    //  Used to prevent "SyntaxError" errors (which provide no detail at all) from flooding our logs.
    //  Also causes a message to be displayed in the app header telling the user to upgrade.
    //  Old/unsupported Edge has a user agent like this: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/64.0.3282.140 Safari/537.36 Edge/18.17763
    //  The new (Chromium based) Edge has a user agent like this: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.93 Safari/537.36 Edg/96.0.1054.53
    //  Notice the difference of "Edge/" vs "Edg/"
    (navigator as any).IsUnsupportedEdgeBrowser = true;
}

/** IE10 and IE11 requires the following for the Reflect API. */
// import 'core-js/es6/reflect';


/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.



/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js';

//  Pollyfill needed to provide the KeyboardEvent.key property which does not exist in some older browsers
//  (including Chrome < 51) and for the .code property which does not exist in Edge.
import '../src/assets/scripts/KeyboardEventPolyfill.js';

//  This is a hack to fix a bug in Chrome with Array.reduce: https://bugs.chromium.org/p/chromium/issues/detail?id=1049982
//  It randomly causes AbstractControl.get() to return null when the FormControl does exist.
//  Also see all of these Angular issues:
//      https://github.com/angular/angular/issues/35190
//      https://github.com/angular/angular/issues/35219
//      https://github.com/angular/angular/issues/35214
(function () {
    function getChromeVersion() {
        const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
        return raw ? parseInt(raw[2], 10) : false;
    }

    const chromeVersion = getChromeVersion();
    if (chromeVersion && chromeVersion >= 80) {
        Array.prototype.reduce = function (callback /*, initialValue*/) {
            'use strict';
            if (this == null) {
                throw new TypeError('Array.prototype.reduce called on null or undefined');
            }
            if (typeof callback !== 'function') {
                throw new TypeError(callback + ' is not a function');
            }
            let t = Object(this), len = t.length >>> 0, k = 0, value;
            if (arguments.length === 2) {
                value = arguments[1];
            } else {
                while (k < len && !(k in t)) {
                    k++;
                }
                if (k >= len) {
                    throw new TypeError('Reduce of empty array with no initial value');
                }
                value = t[k++];
            }
            for (; k < len; k++) {
                if (k in t) {
                    value = callback(value, t[k], k, t);
                }
            }
            return value;
        };
    }
})();

//  This was another possibility of fixing the Chrome 80 issue.  It worked great locally.  But when built and pushed out
//  to the dev site, it did not work at all and actually made things much worse.
//(function () {
//    function getChromeVersion() {
//        const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

//        return raw ? parseInt(raw[2], 10) : false;
//    }

//    const chromeVersion = getChromeVersion();
//    if (chromeVersion && chromeVersion >= 80) {
//        const arrayReduce = Array.prototype.reduce;
//        let callback;
//        Object.defineProperty(Array.prototype, 'reduce', {
//            value: function (cb, ...args) {
//                callback = cb;
//                return arrayReduce.call(this, callback, ...args);
//            }
//        });
//    }
//})();
